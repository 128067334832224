@media screen and (max-width: 768px) {
  #root {
    padding-bottom: 0;
  }
  p.bigger-text {
    font-size: 1rem;
    text-align: justify;
  }
  .header {
    margin-bottom: 12px;
  }
  .header .header-content .heading span.h1 {
    font-size: 2.7rem;
    line-height: 3rem;
  }
  .header .header-content .heading span.h2 {
    font-size: 1.3rem;
  }
  .header .header-content img.logo {
    max-height: 120px;
    margin-left: 24px;
  }
  .header .navigation a:link,
  .header .navigation a:visited {
    font-size: 0.9rem;
    padding: 4px 12px;
  }
  .hidden-mobile {
    display: none !important;
  }
  footer {
    position: relative;
    bottom: 0;
    margin-top: 40px;
  }
  .stage-modal-text {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 616px) {
  .header .header-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .header .header-content .heading {
    justify-content: flex-end;
    align-items: flex-end;
  }
  .header .header-content .heading span.h1 {
    font-size: 1.6rem;
    line-height: 1.8rem;
    text-align: right;
  }
  .header .header-content .heading span.h2 {
    font-size: 0.9rem;
    line-height: 1.3rem;
    text-align: right;
  }
  .header .header-content img.logo {
    width: 30vw;
    margin-bottom: 24px;
  }
  .header .navigation a:link,
  .header .navigation a:visited {
    font-size: 0.7rem;
    font-weight: lighter;
    padding: 4px 10px;
  }
}
