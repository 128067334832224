@font-face {
  font-family: "Ubuntu";
  src: url("font/Ubuntu-Regular.woff2") format("woff2"), url("font/Ubuntu-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Ubuntu";
  src: url("font/Ubuntu-Light.woff2") format("woff2"), url("font/Ubuntu-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Ubuntu";
  src: url("font/Ubuntu-Bold.woff2") format("woff2"), url("font/Ubuntu-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Ubuntu";
  src: url("font/Ubuntu-Medium.woff2") format("woff2"), url("font/Ubuntu-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
:root {
  font-family: "Ubuntu", sans-serif !important;
}
body {
  font-family: "Ubuntu", sans-serif;
  /* background: transparent url(./gradient_bg.jpg) repeat-x;
    background-size: 100% 100%; */
  background-color: #1c3351;
  height: 100%;
}
#root {
  padding-bottom: 40px;
}
.bigger-text {
  font-size: 1.1rem;
  text-align: justify;
  font-weight: 200;
}
.header {
  padding-top: 32px;
  padding-bottom: 0px;
  margin-bottom: 24px;
}
.header .header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 24px;
}
.header .header-content .logo-link {
  width: 18vw;
  min-width: 180px;
  height: auto;
  margin-right: auto;
}
.header .header-content img.logo {
  max-height: 120px;
}
.header .header-content .heading {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.header .header-content .heading span.h1 {
  font-size: 3.2rem;
  font-weight: 800;
  line-height: 3rem;
  color: #fafafa;
  text-align: right;
}
.header .header-content .heading span.h2 {
  font-size: 1.4rem;
  font-weight: 400;
  color: #189ccc;
  line-height: 2rem;
  text-align: right;
}
.header .navigation {
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
  column-gap: 12px;
}
.header .navigation a:link,
.header .navigation a:visited {
  font-size: 1rem;
  transition: all 0.3s linear;
  border-radius: 12px;
  color: #aaaaaa;
  padding: 6px 14px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .navigation a:link.active,
.header .navigation a:visited.active {
  background-color: #189ccc;
  color: #ffffff;
}
.header .navigation a:hover {
  color: #189ccc;
}
.stage {
  margin-top: 12px;
}
.stage .current-session-heading {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  color: #999999;
}
.stage .current-session-title-heading {
  font-size: 1.8rem;
  color: #333333;
  margin: 0;
  padding: 0;
  line-height: 2rem;
}
.stage .current-session-description {
  font-size: 1rem;
  color: #555555;
}
.schedule span.roomname {
  color: #666666;
  font-weight: bold;
}
.schedule .ant-steps-item-title {
  font-size: 1.1rem;
}
.schedule .ant-steps-item-description b {
  color: #189ccc;
}
.schedule .schedule-card {
  transition: all 3s linear;
  height: auto;
}
.schedule .schedule-card .ant-card-body {
  transition: all 3s linear;
  height: auto;
}
.partners {
  text-align: center;
}
.partners h1 {
  color: #119dd9;
  text-transform: uppercase;
}
.partners h2.partner-category-heading {
  text-transform: uppercase;
  margin: 24px 0;
}
.partners .ant-card-body {
  padding: 64px;
}
.partners .ant-row {
  justify-content: center;
}
.partners .ant-row img {
  max-width: 100%;
  height: auto;
}
.stage-modal-text {
  font-size: 1.3rem;
}
footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 32px 0 0px 0;
  position: relative;
  bottom: 0;
  margin-top: 40px;
  font-size: 0.7rem !important;
  line-height: 0.9rem !important;
  color: #fafafa;
}
footer a:link,
footer a:visited {
  color: #ffffff;
}
footer a:hover {
  color: #189ccc;
}
footer .ant-col {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem !important;
  line-height: 0.9rem !important;
}
footer .ant-col .social {
  margin-left: 6px;
}
footer .ant-col .social .anticon {
  font-size: 1.4rem;
}
